export const qaDomain = "https://app.zymple.io";
export const iconPath = `${qaDomain}/recursos/icons`;

// routes: MenuWrapper
export const BILLING_ROUTE = qaDomain + "/facturacion";
export const ACCOUNT_ROUTE = qaDomain + "/mi-cuenta";
export const TEAM_ROUTE = qaDomain + "/empleados";
export const COMISSION_ROUTE = qaDomain + "/comisiones";
export const PRODUCTS_ROUTE = qaDomain + "/almacen";
export const LOUNGES_ROUTE = qaDomain + "/locales";
export const BOOKING_ROUTE = qaDomain + "/reservas";
export const PROMOTION_ROUTE = qaDomain + "/promociones";
export const TAXES_ROUTE = qaDomain + "/impuestos";
export const SERVICES_ROUTE = qaDomain + "/servicios";
export const CASH_REGISTER = qaDomain + "/cajas";
export const CLIENTS_ROUTE = qaDomain + "/clientes";
export const STATISTICS_ROUTE = qaDomain + "/estadisticas";
